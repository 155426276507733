import React from 'react';
import dataCard from '../assets/data/data-home-card';
import About from '../components/about/AboutHome';
import Banner from '../components/banner/HomeBanner2';
import Create from '../components/create/Create2';
import TopBanner from '../components/banner/HomeBanner';
import Footer from '../components/footer/Footer';
import Testimonials from '../components/testimonials/HomeTestimonials';
import dataTestimonials from "../assets/data/data-testimonials";
import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import data from '../assets/data/dataPartner';
import headerVideo from '../assets/home/HeroVideo.mp4';
import createImg from "../assets/home/CTA.png";
function Home01(props) {
    //const video= "https://player.vimeo.com/video/937303901?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0";
    const video = "https://www.youtube.com/embed/KvTuolZ36MA?controls=0&modestbranding=1&showinfo=0&rel=0"
    const contactData = {
        heading: 'Get In Touch',
        desc: 'Don’t wait. Contact us today to unleash the power of XR and transform the way you do business.',
        btn: true,
        btnTitle: 'Get In Touch',
        link: '/contact',
        img:createImg
    }
    const bannerData = {
        subheading:"Leading the Way in XR, AI and Metaverse",
        // heading: 'Your Gateway to the Future of Technology',
        video: headerVideo
    }
    function handleClick(event) {
        event.preventDefault();
      }
      
    return (
        <div className="home-2 wrapper">

            <TopBanner data={bannerData} />

            <About />

            <Banner data={dataCard} />
            {video && ( <section className="project">
                    <div className="shape right"></div>
                        <div className="container">
                        <div className="row text-center">
                            <div className="col-xl-12 col-md-12">
                                <h3 className="heading p-4">Navigate to the virtual landscape with AVR Labs</h3>
                                <div className="project-box">
                                    <div style={{ padding: '56.25% 0 0 0',position: 'relative' }}>
                                    <iframe
                                        src={video}
                                        frameBorder="0"
                                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                                        title="AVRLabs"
                                        className="embed-responsive-item"
                                    ></iframe>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </section>)}
            <section className="partner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-text center">
                                <h3 className="heading">We are partnered with top brands</h3>
                            </div>

                            <Swiper
                                className="brands-swiper"
                                spaceBetween={30}
                                modules={[Pagination,Autoplay]}
                                autoplay={{
                                    delay:3000,
                                    disableOnInteraction: false,
                                }}                              
                                breakpoints={{
                                    0: {
                                        slidesPerView: 2,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                    },
                                    991: {
                                        slidesPerView: 6,
                                    },
                                }}
                                loop={true}

                            >
                                {
                                    data.map(idx => (
                                        <SwiperSlide key={idx.id}>
                                            <a href="#" onClick={handleClick}><img src={idx.img} alt="AVRLabs"/></a>
                                        </SwiperSlide>
                                    ))
                                }

                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>

            <Testimonials data={dataTestimonials} />

            <Create data={contactData}/>

            <Footer />
        </div>
        

    );
}

export default Home01;