import React from 'react';
import Banner from '../../components/banner/ServiceBanner';
import Footer from '../../components/footer/Footer';
import Create from '../../components/create/Create2';
import AboutService from '../../components/about/AboutService';
import ServiceToken from '../../components/token/ServiceToken';
import ServiceToken2 from '../../components/token/ServiceToken2';
import MetaverseAboutData from '../../assets/data/data-metaverse-about';
import SolutionCounter from '../../components/counter/SolutionCounter';
import SpecialityData from '../../assets/data/data-metaverse-speciality';
import SolutionSpeciality from '../../components/speciality/SolutionSpeciality';
import Project from '../../components/project/ProjectService';
import dataProject from '../../assets/data/data-metaverse-project';
import ConsultingImg from '../../assets/Services/metaverse-services/consulting.jpg';
import AppImg from '../../assets/Services/metaverse-services/app development.jpg';
import IntegrationImg from '../../assets/Services/metaverse-services/integration.jpg';
import SpaceImg from '../../assets/Services/metaverse-services/3d space development.jpg';
import AvatarImg from '../../assets/Services/metaverse-services/speciality-icons/metaverse-avatar.jpg';
import TwinImg from '../../assets/Services/metaverse-services/digital twin.jpg';
import MarketplaceImg from '../../assets/Services/metaverse-services/marketplace development.jpg';
import NFTImg from '../../assets/Services/metaverse-services/nft.jpg';
import EnterpriseImg from '../../assets/Services/metaverse-services/enterprise consulting.jpg';
import ContactImg from '../../assets/Services/metaverse-services/cta.png';
import videoPlayP from '../../assets/Services/metaverse-services/speciality-icons/AVR Labs HQ.mp4'


function Metaverse(props) {
    const tokenData4Video = "https://player.vimeo.com/video/929219923?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0";
    const CounterNeed = [
        {
            count: '5x ',
            title: "User engagement increase"
        },
        {
            count: "30% ",
            title: 'Return on investment (ROI) boost'
        },
        {
            count: '47%',
            title: "Compound Annual Growth Rate (CAGR) enhancement"
        },

    ];
    const SpecialityDataHeader = {
        heading: 'Our Metaverse Development Services',
        cls: "col-xl-3 col-md-6"
    };
    const bannerData = {
        heading: 'Metaverse Services',
        subheading: "Immerse Yourself in the Next Frontier of Digital Innovation",
        desc: "",
        button: false,
        btnTitle: "",
        link: '',
    };
    const token2Data2 = {
        // subheading: 'Token Information',
        heading: 'Metaverse Consulting',
        desc: `Get valuable guidance and insights with our consulting services, offering strategic advice, market research, and feasibility studies. Our experts will help you navigate the complexities of the Metaverse, making informed decisions that propel your business forward.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: ConsultingImg
    }
    const token2Data3 = {
        // subheading: 'Token Information',
        heading: 'Metaverse App Development',
        desc: `Immerse your audience in unique Metaverse experiences designed just for your business. Whether you need a gaming platform, virtual event space, or interactive product showcase, our team brings your ideas to life with precision and creativity.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: "",
        videoPlay: videoPlayP
    }
    const token2Data4 = {
        // subheading: 'Token Information',
        heading: 'Metaverse Integration',
        desc: `Merge your existing platforms seamlessly with the Metaverse to expand your virtual presence. Our integration specialists ensure smooth connections and data synchronization between your apps and the Metaverse environment, maximizing efficiency and engagement.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: IntegrationImg
    }
    const token2Data44 = {
        // subheading: 'Token Information',
        heading: 'Metaverse 3D Space Development',
        desc: `Explore limitless possibilities with our expertly crafted 3D virtual environments, mirroring real-world settings. Whether for business use or themed experiences, our team creates immersive spaces that captivate and engage your audience.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: SpaceImg
    }
    const token2Data5 = {
        // subheading: 'Token Information',
        heading: 'Metaverse 3D Avatar Development',
        desc: `Personalize your virtual world with custom 3D avatars that reflect your users' unique identities. Our avatars are designed for authentic interactions and seamless engagement within the Metaverse.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: AvatarImg
    }
    const token2Data6 = {
        // subheading: 'Token Information',
        heading: 'Metaverse Digital Twin Development',
        desc: `Gain insights with real-time data visualization and simulation using our digital twin solutions. Our developers create dynamic digital replicas that empower decision-making and drive innovation.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: 'https://portal.arviewz.com/products/130/modelviewer?logoMobile=20&logoDesktop=20&copyRight=block',
        video: '',
        img: ""
    }
    const token2Data7 = {
        // subheading: 'Token Information',
        heading: 'Metaverse Marketplace Development',
        desc: `Create a thriving digital economy with our NFT marketplace solutions. Our platforms feature secure payment options and intuitive interfaces for seamless transactions in the Metaverse.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: MarketplaceImg
    }
    const token2Data8 = {
        // subheading: 'Token Information',
        heading: 'NFT Integration Into Metaverse',
        desc: `Unlock the power of NFTs in the Metaverse ecosystem. Our services enable businesses to tokenize digital assets, fostering ownership and value exchange in virtual realms.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: NFTImg
    }
    const token2Data9 = {
        // subheading: 'Token Information',
        heading: 'Metaverse Enterprise Development',
        desc: `Transform your business with our tailored Metaverse solutions. From improving communication to driving marketing initiatives, we empower enterprises to thrive in the immersive world of the Metaverse.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: EnterpriseImg
    }
    const contactData = {
        heading: 'Step into a New Dimension of Success',
        desc: 'Partner with us to Transform your business in the Digital World!',
        btn: true,
        btnTitle: 'Request a Demo',
        link: '/contact',
        img: ContactImg
    }
    const projectHeader = {
        heading: 'Industry Application',
        subheading: 'Our Speciality'
    }
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            <div className='main-bottom-space-100'>
                <AboutService data={MetaverseAboutData} />
            </div>
            <div className="mb-5">
                <SolutionCounter data={CounterNeed} heading="Why you need Metaverse?" showimg="false" />
            </div>
            <SolutionSpeciality data={SpecialityData} headerData={SpecialityDataHeader} icon="true"/>
            
            <section>
                <div className="block-text center">
                    <h2 className="text-center">What We Offer</h2>
                    <h6 className="sub-heading text-center my-3"><span>Expanding Digital Horizons</span></h6>
                </div>
            </section>
            <ServiceToken data={token2Data2} />
            <ServiceToken2 data={token2Data3} />

            {/* <ServiceToken data={token2Data4} /> */}
            <ServiceToken data={token2Data5} />
            <ServiceToken2 data={token2Data44} />

            <ServiceToken data={token2Data6} />
            {/* <ServiceToken2 data={token2Data7} /> */}
            <ServiceToken2 data={token2Data8} />
            {/* <ServiceToken data={token2Data9} /> */}
            <section className="about">
                <div className="shape right"></div>
                    <div className="container">
                    <div className="row text-center">
                        <div className="col-xl-12 col-md-12">
                            <div className="project-box mb-0">
                                <div style={{ padding: '56.25% 0 0 0',position: 'relative' }}>
                                <iframe
                                    src={'https://www.spatial.io/s/AVR-Labs-HQ-652fb118da49cf6be9e9c87f?share=4493156842157716149'}
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                    style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                                    title="AVRLabs"
                                    className="embed-responsive-item"
                                ></iframe>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </section>
            <Project data={dataProject} headerData={projectHeader} />
            <Create style={{ marginTop: "100px" }} data={contactData} />
            <Footer />
        </div>
    );
}

export default Metaverse;