import personalizedEvent from "../industries/Events/Personalized-Event-App.jpg";
import aiImg from "../industries/Art/new/ai-to-sell-art.jpg";

const vimeoVideoUrls = {
    arNavigation: "https://www.youtube.com/embed/AXBKfQH8n6k?controls=0&modestbranding=1&showinfo=0&rel=0",
    AI: "https://www.youtube.com/embed/9N_BxO0wA_Y?controls=0&modestbranding=1&showinfo=0&rel=0",
};
const dataItem = [
  {
    id: 1,
    img: '',
    iframe: "",
    title: "",
    titleH3: "AR Navigation Systems",
    desc: `<p>Help attendees locate booths, presentations, and event facilities with augmented reality navigation through their smartphones.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: vimeoVideoUrls['arNavigation'],
    link: "",
  },
  {
    id: 2,
    img: '',
    iframe: 'https://virtualdesignshub.com/viewer/index.php?code=a8f15eda80c50adb0e71943adc8015cf',
    titleH3: "VR for Entertainment & Training",
    title: "",
    desc: `<p>Revolutionize events with VR for Entertainment & Training. Provide immersive experiences, from virtual concerts to training simulations, creating dynamic and memorable engagements for diverse event audiences.<p>`,
    button: true,
    slug: "A02",
    btnTitle: "Learn more",
    video:'' ,
    link: "/services/extended-reality",
  },
  {
    id: 3,
    img: aiImg,
    iframe: '',
    titleH3: "AI-Powered Personal Host",
    title: "",
    topHeadingDesign: true,
    desc: `<p>Provide a personalized visitor journey with an AI assistant that welcomes attendees, guides them through booths, collects feedback, and tracks interests to optimize follow-ups.<p>`,
    button: true,
    slug: "",
    btnTitle: "Get Your Personalized AI Host",
    video: "",
    link: "/contact",
  },
  {
    id: 4,
    img: personalizedEvent,
    iframe: '',
    titleH3: "Personalized Event App",
    title: "",
    headingRight: true,
    desc: `<p>Enhance attendee experience with a custom mobile app offering real-time updates on sessions, personalized schedules, reminders, and interactive maps for seamless navigation of booths and facilities.<p>`,
    button: false,
    topHeadingDesign: true,
    slug: "",
    btnTitle: "Learn more",
    video: "",
    link: "",
  },
  
];

export default dataItem;
