import workshop from "../home/slider/Workshops.jpg";
import AR from "../home/slider/AR.jpg";
import VR from "../home/slider/VR.jpg";
import Metaverse from "../home/slider/Metaverse-Services.jpg";
import Creative from "../home/slider/Creative-Concepts.jpg";
import Innovative from "../home/ServicesSlider/new/Innovative Installation.jpg";
import Consultation from "../home/ServicesSlider/Consultation & workshops.jpg";
import Experiental from "../home/ServicesSlider/Experiental Technology.png";
import Artificial from "../home/ServicesSlider/new/Artificial Intelligence.png";
import Booth from "../home/ServicesSlider/Booth Stand & Event Tech.png";
import Content from "../home/ServicesSlider/new/Design & Content Creation.png";
import Technology from "../home/ServicesSlider/new/Technology Integrated Interior_.png";
import Wayfinding  from "../home/ServicesSlider/AI_AR wayfinder (2).png";
import Holographic from "../home/slider/Holographic-Displays.jpg";
import Virtual from "../home/slider/Virtual-Try-on.jpg";



const dataCard = [
  {
    id: 1,
    img: workshop,
    title: "Consultation & Workshops",
  },
  {
    id: 2,
    img: AR,
    title: "Augmented Reality - AR",
    //link: '/services/A02'
  },
  {
    id: 3,
    img: VR,
    title: "Virtual Reality - VR",
    //link: '/services/A02'
  },
  {
    id: 4,
    img: Metaverse,
    title: "Metaverse Services",
    //link:'/services/A03',
  },
  {
    id: 5,
    img: Creative,
    title: "Innovative installation",
    //link:'/services/A06',
  },
  {
    id: 7,
    img: Holographic,
    title: "Holographic Displays",
    //link:'/solutions/S07',
  },
  {
    id: 9,
    img: Experiental,
    title: "Experiential  technology",
    //link:'/solutions/S08',
  },
  {
    id: 10,
    img: Artificial,
    title: "Artificial Intelligence ",
    //link:'/solutions/S08',
  },
  {
    id: 11,
    img: Booth,
    title: "Booth stand & Event Tech",
    //link:'/solutions/S08',
  },
  {
    id: 12,
    img: Content,
    title: "Design & Content Creation",
    //link:'/solutions/S08',
  },
  {
    id: 13,
    img: Technology,
    title: "Technology integrated interior",
    //link:'/solutions/S08',
  },
  {
    id: 14,
    img: Wayfinding ,
    title: "AR/AI Wayfinding ",
    //link:'/solutions/S08',
  },
];

export default dataCard;
