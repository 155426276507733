import React from 'react';
import ArtBanner from '../../components/banner/ArtBanner.jsx';
import Footer from '../../components/footer/Footer.jsx';
import bannerImg from "../../assets/Industries Hero/Healthcare-Hero.png"
import ServiceToken from '../../components/token/ServiceToken';
import ServiceToken2 from '../../components/token/ServiceToken2';
import Create from '../../components/create/Create2';
import contactImg from '../../assets/Services/have-a-project2.png';
import tokenDataFiveImg from '../../assets/industries/Health Care/Custom-AI-Virtual-Assistant.jpg';
import tokenDataTwoImg from '../../assets/industries/Health Care/VR-Therapy.jpg';
import tokenDataSixImg from '../../assets/industries/Health Care/AI-Powered-AR-Navigation.jpg';
import tokenDataSevenImg from '../../assets/industries/Health Care/Aerial-Touch-Screens.jpg';
import tokenDataNineImg from '../../assets/industries/Health Care/AR-Remote-Assistant.jpg';
//import tokenDatafourteenImg from '../../assets/industries/Health Care/Cardboard-Solutions.jpg';
import tokenDataTenImg from '../../assets/industries/Health Care/Skin Analysis.gif';
import tokenDataElevenImg from '../../assets/industries/Health Care/Augmented-Reality-(AR)-for-Kids.jpg';
import tokenDataTweImg from '../../assets/industries/Health Care/Virtual-Reality-(VR).jpg';
import tokenDataThirImg from '../../assets/industries/Health Care/Kinect-Solutions.jpg';


function Healthcare(props) {
    const desc ="Transform the way you showcase properties with our technology-driven solutions.  Experience a new era of immersive engagement that captivates clients and take your projects to new heights.";
    const vimeoVideoUrls = {
        xrTraining: "https://www.youtube.com/embed/SZplwg6u-Ec?controls=0&modestbranding=1&showinfo=0&rel=0",
        vrTraining: "https://www.youtube.com/embed/iG60elySLTk?controls=0&modestbranding=1&showinfo=0&rel=0",
    };
    const itemBtn = {
        buttonFlag: true,
        button1: true,
        btnTitle1: "Book a Call",
        link1: "https://calendly.com/raed-avr/",
        button2: true,
        btnTitle2: "Request a Quote",
        link2: "/contact",
        target: "blank"
    }
    const tokenDataOne = {
        heading: 'XR Trainings',
        desc: `<p class="mb-2">Revolutionize medical training with immersive XR experiences. Practice lifelike scenarios to boost confidence and skills, enhancing patient care.</p>`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: vimeoVideoUrls["xrTraining"],
        img: "",
        isShape: false
    }

    const tokenDataTwo = {
        heading: 'VR Therapy',
        desc: `<p class="mb-2">Enhance team learning through VR simulations, allowing hands-on practice in realistic scenarios for improved confidence and reduced errors.</p>`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: '',
        img: tokenDataTwoImg,
        isShape: false
    }

    const tokenDataFour = {
        heading: 'VR Trainings',
        desc: `<p class="mb-2">Enhance team learning through VR simulations, allowing hands-on practice in realistic scenarios for improved confidence and reduced errors.</p>`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: vimeoVideoUrls["vrTraining"],
        img: "",
        isShape: false
    }

    const tokenDataFive = {
        heading: 'Custom AI Virtual Assistant',
        desc: `<p class="mb-2">Streamline patient interactions with AI assistants for quick answers and scheduling, enabling your team to focus on quality care.</p>`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: '',
        img: tokenDataFiveImg,
        isShape: false
    }

    const tokenDataSix = {
        heading: 'AI-Powered AR Navigation',
        desc: `<p class="mb-2">Guide patients effortlessly to appointments with AR navigation, reducing stress and improving facility efficiency.</p>`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: "",
        img: tokenDataSixImg,
        isShape: false
    }

    const tokenDataSeven = {
        heading: 'Aerial Touch Screens',
        desc: `<p class="mb-2">Provide hands-free, intuitive access to medical records and resources, ensuring engagement in a hygienic environment.</p>`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: '',
        img: tokenDataSevenImg,
        isShape: false
    }

    const tokenDataEight = {
        heading: 'Virtual Tours',
        desc: `<p class="mb-2">Showcase facilities online with virtual tours, building trust and confidence before visits.</p>`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: "https://www.youtube.com/embed/MW8hrXWlw5A?controls=0&modestbranding=1&showinfo=0&rel=0",
        img: "",
        isShape: false
    }

    const tokenDataNine = {
        heading: 'AR Remote Assistant',
        desc: `<p class="mb-2">Enhance remote consultations with real-time AR guidance, improving communication and care quality.</p>`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: '',
        img: tokenDataNineImg,
        isShape: false
    }

    const tokenDataTen = {
        heading: 'Skin Analysis',
        desc: `<p class="mb-2">Leverage AI-driven tools for accurate dermatology assessments and personalized treatments, accessible on various devices.</p>`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: "",
        img: tokenDataTenImg,
        isShape: false
    }

    const tokenDataEleven = {
        heading: 'Augmented Reality (AR) for Kids',
        desc: `<p class="mb-2">Use AR animations to entertain and educate young patients, easing anxiety during procedures.</p>`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: '',
        img: tokenDataElevenImg,
        isShape: false
    }

    const tokenDataTwe = {
        heading: 'Virtual Reality (VR)',
        desc: `<p class="mb-2">Immerse patients in calming VR environments or engaging games to reduce stress in waiting areas.</p>`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: "",
        img: tokenDataTweImg,
        isShape: false
    }

    const tokenDataThir = {
        heading: 'Touchless Intertainment for Waiting time',
        desc: `<p class="mb-2">Incorporate motion-sensing technology for fun physical therapy activities and tailored treatments.</p>`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: '',
        img: tokenDataThirImg,
        isShape: false
    }

    // const tokenDataFourteen = {
    //     // heading: 'Cardboard Solutions',
    //     // desc: `<p class="mb-2">Offer affordable VR experiences using smartphones for stress relief and interactive entertainment.</p>`,
    //     // btn: false,
    //     // btnTitle: "",
    //     // link: '',
    //     // iframe: '',
    //     // video: '',
    //     // img: tokenDatafourteenImg,
    //     // isShape: false
    // }

    const contactData = {
        heading: 'Ready to advance your care?',
        desc: 'Transform healthcare delivery and enhance patient experiences with our innovative solutions.',
        btn: true,
        target: 'blank',
        btnTitle: 'Contact us today!',
        link: '/contact',
        img: contactImg
    }
    return (
        <div className='home-2 wrapper'>
            <ArtBanner image={bannerImg} heading="Healthcare Solutions for a Better Tomorrow" subheading="Industries" desc={desc} itemBtn={itemBtn}/>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h3 className="heading mb-0">
                                    Patient Entertainment Solutions
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ServiceToken data={tokenDataOne} />
            <ServiceToken2 data={tokenDataTwo} />

            <ServiceToken data={tokenDataFour} />
            <ServiceToken2 data={tokenDataFive} />

            <ServiceToken data={tokenDataSix} />
            <ServiceToken2 data={tokenDataSeven} />

            <ServiceToken data={tokenDataEight} />
            <ServiceToken2 data={tokenDataNine} />

            <ServiceToken data={tokenDataTen} />

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-5">
                            <div className="block-text center">
                                <h3 className="heading mb-0">
                                    Patient Entertainment Solutions
                                </h3>
                                <p className="my-2 mb-2">
                                    Innovative technologies to create stress-free, engaging experiences for young patients.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ServiceToken2 data={tokenDataEleven} />

            <ServiceToken data={tokenDataTwe} />
            <ServiceToken2 data={tokenDataThir} />

            {/* <ServiceToken data={tokenDataFourteen} /> */}

            <Create style={{ marginTop: "100px" }} data={contactData} />
            <Footer />
        </div>
    );
}

export default Healthcare;