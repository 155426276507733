
import img1 from '../partners/DMF.png'
import img2 from '../partners/Orange.png'
import img3 from '../partners/ESE.png'
import img4 from '../partners/MOE.png'
import img5 from '../partners/aramco-1024x310.png'
import img6 from '../partners/bni.png'
import img7 from '../partners/UJM.png'
import img8 from '../partners/Rattan-House-white.png'
import img9 from '../partners/Bateel.png'
import img10 from '../partners/Asset-1.png'
import img11 from '../partners/Asset-9.png'
import img12 from '../partners/new/BNW.png'
import img13 from '../partners/new/Cop28 logo.png'
import img14 from '../partners/new/GrandSeal.png'
import img15 from '../partners/new/Ujman.png'
import img16 from '../partners/new/WFES.png'
import img17 from '../partners/new/kit.png'
import img18 from '../partners/new/2.png'
import img19 from '../partners/new/3.png'
import img20 from '../partners/new/Vidamax.png'

const dataPartner = [

    {
        id: 1,
        img: img1,
    },
    {
        id: 2,
        img: img2,
    },
    {
        id: 3,
        img: img3,
    },
    {
        id: 4,
        img: img4,
    },
    {
        id: 5,
        img: img5,
    },
    {
        id: 6,
        img: img6,
    },
    {
        id: 7,
        img: img7,
    },
    {
        id: 8,
        img: img8,
    },
    {
        id: 9,
        img: img9,
    },
    {
        id: 10,
        img: img10,
    },
    {
        id: 11,
        img: img11,
    },
    {
        id: 12,
        img: img12,
    },
    {
        id: 13,
        img: img13,
    },
    {
        id: 14,
        img: img14,
    },
    {
        id: 15,
        img: img15,
    },
    {
        id: 16,
        img: img16,
    },
    {
        id: 17,
        img: img17,
    },
    {
        id: 18,
        img: img18,
    },
    {
        id: 19,
        img: img19,
    },
    {
        id: 20,
        img: img20,
    },

]

export default dataPartner;