import virtualdesignsImg from "../industries/Architecture/Virtual Design.jpg";
import renderingimg from "../industries/Architecture/rendering.jpg";
import traniningWorkshopImg from "../industries/Architecture/workshop & training.jpg";

const vimeoVideoUrls = {
  designsHubSoftware: "https://player.vimeo.com/video/940765178?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
};
const dataItem = [
  {
    id: 1,
    img: virtualdesignsImg,
    iframe: '',
    title: "Virtual Designs",
    desc: `<p>Transform architectural visions into immersive Virtual Designs, providing digital representations that enable exploration and visualization, enhancing the design process and stakeholder communication.<p>`,
    button: true,
    slug: "A08",
    btnTitle: "Learn more",
    video: '',
    link: "/services/interactive-virtual-tours",
  },
  {
    id: 2,
    img: '',
    iframe: '',
    title: "Virtual Designs Hub Software",
    desc: `<p>Streamline architectural workflows with Virtual Designs Hub Software. Facilitate collaboration, visualization, and project management, optimizing efficiency and enhancing communication within architectural teams.<p>`,
    button: true,
    slug: "S02",
    btnTitle: "Learn more",
    video:vimeoVideoUrls['designsHubSoftware'] ,
    link: "/solutions/virtual-designs-hub",
  },
  {
    id: 3,
    img: renderingimg,
    title: "Rendering",
    desc: `<p>Elevate architectural presentations with Rendering. Transform 3D models into photorealistic images, providing clients and stakeholders a visually stunning preview of architectural designs before construction begins.<p>`,
    button: true,
    slug: "A05",
    btnTitle: "Learn more",
    video: '',
    link: "/services/virtual-web-development",
  },
  {
    id: 4,
    img: '',
    iframe: 'https://portal.arviewz.com/products/328/modelviewer',
    title: "3D Modeling",
    desc: `<p>Shape architectural concepts into reality through 3D Modeling. Create detailed and accurate digital representations, aiding in visualization, analysis, and communication throughout the architectural design and planning phases.<p>`,
    button: true,
    slug: "A02",
    btnTitle: "Learn more",
    video: '',
    link: "/services/extended-reality",
  },
  {
    id: 5,
    img: traniningWorkshopImg,
    iframe: '',
    title: "Training and Workshops",
    topHeadingDesign: true,
    desc: `<p>Enhance architectural skills with specialized Training and Workshops. Provide professionals with hands-on experiences, covering virtual design tools, rendering techniques, and advanced 3D modeling for continual professional development.<p>`,
    button: true,
    slug: "A01",
    btnTitle: "Learn more",
    video: '',
    link: "/services/A01",
  },  
];

export default dataItem;
