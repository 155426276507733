import interactiveVirtualTourimg from "../industries/Real Estate/real estate tour.jpg";
import interactiveInstallationsImg from "../industries/Real Estate/interactive-installations.png";
import printerImg from "../industries/Real Estate/Marker-Based-AR.jpg";
import renderingimg from "../industries/Architecture/rendering.jpg";
import traniningWorkshopImg from "../industries/Architecture/workshop & training.jpg";

const vimeoVideoUrls = {
    virtualDesigns: "https://player.vimeo.com/video/929219923?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
    interactiveInstallations: "https://player.vimeo.com/video/936100081?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
    designsHubSoftware: "https://player.vimeo.com/video/940765178?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
    AI: "https://www.youtube.com/embed/9N_BxO0wA_Y?controls=0&modestbranding=1&showinfo=0&rel=0",
    ARVR: "https://www.youtube.com/embed/mq6BGVrQalo?controls=0&modestbranding=1&showinfo=0&rel=0",
    Tengible: "https://www.youtube.com/embed/1GBpVaYEwR8?controls=0&modestbranding=1&showinfo=0&rel=0",
    Intractive: "https://www.youtube.com/embed/UfvMgd7pyIQ?controls=0&modestbranding=1&showinfo=0&rel=0",
    ThreeDVirtual: "https://www.youtube.com/embed/4nuzN83VoCI?controls=0&modestbranding=1&showinfo=0&rel=0",
    modelAr: "https://player.vimeo.com/video/936106630?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
    
};
const dataItem = [
  {
    id: 1,
    img: '',
    title: "Tangible Table",
    backTransparent: true,
    desc: `<p>Present property portfolios interactively with photos, videos, and PDFs grouped by tags. Perfect for real estate events and client meetings.</p>`,
    button:false ,
    slug: "",
    btnTitle: "",
    video: vimeoVideoUrls['Tengible'],
    link: "",
  },
  {
    id: 2,
    img: interactiveVirtualTourimg,
    title: "Interactive Virtual Tour",
    desc: `<p>Engage clients with Interactive Virtual Tours that allow them to explore properties from anywhere. Stunning visuals and interactive elements help users discover every detail, making informed decisions effortless.<p>`,
    button: true,
    slug: "A08",
    btnTitle: "Learn More",
    video: "",
    link: "/services/interactive-virtual-tours",
  },
  {
    id: 3,
    img: '',
    iframe: 'https://virtualdesignshub.com/viewer/zohour',
    title: "Virtual Property Tours",
    desc: `<p>Capture attention with Virtual Property Tours.</p>
    <p>Let your clients explore your developments as if they were physically present, offering a comprehensive understanding of space and design. Ideal for attracting remote investors that leaves a lasting impression and transforms curiosity into commitment.<p>`,
    button: true,
    slug: "S02",
    btnTitle: "Learn More",
    video: '',
    link: "/solutions/virtual-designs-hub",
  },
  {
    id: 4,
    img: interactiveInstallationsImg,
    title: "Interactive Interior Design",
    desc: `<p>Visualize layouts, furniture, and designs in real-time to help clients make confident decisions. Turn static presentations into immersive experiences.<p>`,
    button: true,
    slug: "A06",
    btnTitle: "Learn More",
    //video: vimeoVideoUrls['interactiveInstallations'],
    link: "/services/event-tech-and-booth-stands",
  },
  
  // {
  //   id: 6,
  //   img: '',
  //   iframe:'https://portal.arviewz.com/products/346/modelviewer?arModel=true',
  //   title: "Smart Stands",
  //   desc: `<p>Smart stands redefine real estate presentations. Integrating interactive displays and technology, they offer immersive property experiences. Engage clients with digital floor plans, VR property tours, and dynamic visualizations for informed decision-making.<p>`,
  //   button: false,
  //   slug: "",
  //   btnTitle: "",
  //   video: '',
  //   link: "",
  // },
  {
    id: 7,
    img: '',
    title: "AR Property Visualization",
    backTransparent: true,
    desc: `<p>Bring designs to life with AR Property Visualization. Clients can see how layouts and designs would look in real time, deepening their connection to the projects and encouraging quicker decisions.</p>`,
    button:false ,
    iframe2: 'https://portal.arviewz.com/products/328/modelviewer',
    slug: "",
    btnTitle: "",
    video: vimeoVideoUrls['modelAr'],
    link: "",
  },
  {
    id: 8,
    img: '',
    title: "VR and AR Home Staging",
    backTransparent: true,
    desc: `<p>Transform property presentations with VR and AR Home Staging. Create customizable spaces that highlight each property's unique charm, allowing clients to envision their future homes and fostering emotional connections.</p>`,
    button:false ,
    slug: "",
    btnTitle: "",
    video: vimeoVideoUrls['ARVR'],
    link: "",
  },
  {
    id: 16,
    img: "",
    title: 'AR Marketing',
    isLink: false,
    classCustom: 'inner-box',
    btn: false,
    btnTitle: "But Token",
    link: '',
    iframe: 'https://portal.arviewz.com/products/620/modelviewer?arModel=true',
    video: '',
    desc: 'Elevate customer experiences with interactive brochures, menus, and more.'
  },

  
  
  {
    id: 9,
    img: '',
    title: "Interactive Table",
    backTransparent: true,
    desc: `<p>Explore properties through a dual-screen system showcasing floor plans on a horizontal display and 3D walkthroughs on a vertical screen for an engaging and detailed view.</p>`,
    button:false ,
    slug: "",
    btnTitle: "",
    video: vimeoVideoUrls['Intractive'],
    link: "",
  },

  {
    id: 10,
    img: '',
    title: "3D 360° Virtual Tours",
    backTransparent: true,
    desc: `<p>Offer immersive property walkthroughs anytime, anywhere. Our 3D 360° tours let clients explore spaces remotely, providing a realistic, interactive experience that enhances decision-making and boosts engagement.</p>`,
    button:false ,
    slug: "",
    btnTitle: "",
    video: vimeoVideoUrls['ThreeDVirtual'],
    link: "",
  },

  {
    id: 11,
    img: printerImg,
    title: "AR Printouts",
    backTransparent: true,
    desc: `<p>Transform traditional brochures into interactive experiences. With AR-enabled printouts, potential buyers can scan QR code to view 3D models, explore interiors, and access key details instantly—bridging the gap between print and digital engagement.</p>`,
    button:false ,
    slug: "",
    btnTitle: "",
    video: "",
    link: "",
    scan:true
  },

  {
    id: 12,
    img: '',
    iframe: '',
    title: "Virtual Designs Hub Software",
    desc: `<p>Streamline architectural workflows with Virtual Designs Hub Software. Facilitate collaboration, visualization, and project management, optimizing efficiency and enhancing communication within architectural teams.<p>`,
    button: true,
    slug: "S02",
    btnTitle: "Learn more",
    video:vimeoVideoUrls['designsHubSoftware'] ,
    link: "/solutions/virtual-designs-hub",
  },
  {
    id: 13,
    img: renderingimg,
    title: "Rendering",
    desc: `<p>Elevate architectural presentations with Rendering. Transform 3D models into photorealistic images, providing clients and stakeholders a visually stunning preview of architectural designs before construction begins.<p>`,
    button: true,
    slug: "A05",
    btnTitle: "Learn more",
    video: '',
    link: "/services/virtual-web-development",
  },
  {
    id: 14,
    img: '',
    iframe: 'https://portal.arviewz.com/products/328/modelviewer',
    title: "3D Modeling",
    desc: `<p>Shape architectural concepts into reality through 3D Modeling. Create detailed and accurate digital representations, aiding in visualization, analysis, and communication throughout the architectural design and planning phases.<p>`,
    button: true,
    slug: "A02",
    btnTitle: "Learn more",
    video: '',
    link: "/services/extended-reality",
  },
  {
    id: 15,
    img: traniningWorkshopImg,
    iframe: '',
    title: "Training and Workshops",
    topHeadingDesign: true,
    desc: `<p>Enhance architectural skills with specialized Training and Workshops. Provide professionals with hands-on experiences, covering virtual design tools, rendering techniques, and advanced 3D modeling for continual professional development.<p>`,
    button: true,
    slug: "A01",
    btnTitle: "Learn more",
    video: '',
    link: "/services/A01",
  }, 
  {
    id: 5,
    img: '',
    title: "AI Virtual Beings",
    desc: `<p>Level up your client interactions with AI Virtual Beings. These intelligent avatars provide instant support and personalized insights, allowing your team to focus on building relationships and strategic initiatives.<p>`,
    button: true,
    slug: "S03",
    btnTitle: "Learn More",
    video: vimeoVideoUrls['AI'],
    link: "/solutions/ai-virtual-beings",
  },

];

export default dataItem;
