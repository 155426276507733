import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "../button/Button";
ArtBanner.propTypes = {
  image: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  desc: PropTypes.string,
  itemBtn: PropTypes.object
};
ArtBanner.defaultProps = {
  heading: "",
  subheading: "",
  desc: '',
  itemBtn: {
    buttonFlag: false,
    button1: "",
    btnTitle1: "",
    link1: "",
    button2: "",
    btnTitle2: "",
    link2: "",
    target: ""
  },
};
function ArtBanner(props) {
  const { image, heading, subheading, desc, itemBtn, videoSrc } = props;
  const bannerStyle = {
    position: "relative",
    minHeight: "400px",
    backgroundImage: `url(${image})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };
  return (
    <section className="banner top-industry-banner s2 height-85vh" style={bannerStyle}>

<video autoPlay loop muted playsInline className="background-video">
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      
      {(heading || subheading || desc) &&
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="block-text center aligned-center-div">
                  {heading && <h2 className="heading mt-5">{heading}</h2>}
                  {subheading && <h6 className="sub-heading">
                    <span>{subheading}</span>
                  </h6>}
                  {desc && <p className="mb-34 font-20 pd-50">{desc}</p>}

                  {itemBtn.buttonFlag &&
                    <div className="row mt-3">
                      <div className="col-lg-6 col-12 text-end">
                        {itemBtn.button1 && (<Button title={itemBtn.btnTitle1} link={itemBtn.link1} target={itemBtn.target ? itemBtn.target : ""} />)}
                      </div>
                      <div className="col-lg-6 col-12 text-start">
                        {itemBtn.button2 && (<Button title={itemBtn.btnTitle2} link={itemBtn.link2} target={itemBtn.target ? itemBtn.target : ""} />)}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>}
    </section>
  );
  
}

export default ArtBanner;
