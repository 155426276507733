import React from 'react';
import ArtDetails from '../../components/art/ArtDetails.jsx';
import ArtBanner from '../../components/banner/ArtBanner.jsx';
import Footer from '../../components/footer/Footer.jsx';
import bannerImg from "../../assets/Industries Hero/Marketing-Hero.jpg"
import MarketingData from "../../assets/data/marketing.js";
import Button from "../../components/button/Button";
import gameGif from '../../assets/Services/interactive-games/Snapchat lens gif.gif';
import snapApp from '../../assets/Services/interactive-games/Snapchat logo.png';
import gameQR from '../../assets/Services/interactive-games/gameQR.png';

function Marketing(props) {
    const desc ="Marketing involves activities and strategies aimed at promoting and selling products or services. It includes market research, advertising, branding, and customer engagement, with the goal of attracting, satisfying, and retaining customers while achieving organizational objectives.";
    const MarketingDataSecond = [
        {
          id: 1,
          img: '',
          iframe: '',
          title: "",
          titleH3: "AR Kids Entertainment: Color & Create",
          desc: `<p>Captivate young guests with our interactive “Color & Create” experience, where kids color animal drawings that come to life in 3D with augmented reality. This engaging experience keeps families returning and enhances their overall visit.<p>`,
          button: true,
          slug: "",
          btnTitle: "Explore Kid-Friendly AR Experiences",
          video: "https://www.youtube.com/embed/QI7aoIQfo5w?controls=0&modestbranding=1&showinfo=0&rel=0",
          link: "",
        },
        {
            id: 8,
            img: '',
            iframe: '',
            title: "AR Geo Spatial",
            desc: `<p>Enhance marketing strategies with AR Geo-Spatial, providing location-based augmented reality experiences for targeted campaigns, delivering dynamic and location-specific content to engage audiences.<p>`,
            button: true,
            slug: "A02",
            btnTitle: "Learn more",
            video: "https://player.vimeo.com/video/936132185?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
            link: "/services/extended-reality",
          },
        
    ];
    return (
        <div className='home-2 wrapper'>
            <ArtBanner image={bannerImg} heading="Experiential Marketing" subheading="Industries" desc={desc}/>
            <ArtDetails data={MarketingData}/>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 pb-5">
                            <div className="block-text center">
                                <h3 className="heading">Custom Snapchat Lens</h3>
                                <p>Boost your social media presence with a Snapchat lens unique to your venue. Guests capture and share their experiences, generating organic buzz and attracting new customers.s</p>
                                <Button title="Get Your Customized Snapchat Lens" link="/" target="blank" />
                            </div>
                        </div>
                    </div>
                    <div className="row text-center align-items-center">
                        <div className="col-xl-5 col-md-5 col-sm-5 col-12 mb-4 d-flex justify-content-center postion-relative">
                            <img src={gameGif} alt="AVRlabs Game" className="mxw-100" />
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-4 col-12 mb-4 d-flex justify-content-center postion-relative">
                            <div>
                                <img src={snapApp} alt="AVRlabs Game" className="mxw-100" />
                                <h6 className='mt-3'>Try this Snapchat Lens</h6>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3 col-sm-3 col-12 d-flex justify-content-center">
                            <img src={gameQR} alt="AVRlabs Game" className="mxw-100" />
                        </div>
                    </div>
                </div>
            </section>
            <ArtDetails data={MarketingDataSecond}/>
            <Footer />
        </div>
    );
}

export default Marketing;