import virtualYearbookImg from "../industries/Education/virtual yearbook.jpg";
import immersiveRealityImg from "../industries/Education/immersive reality.jpg";
import workshopsImg from "../industries/Education/workshop & training.jpg";
import AiTutors from "../industries/Education/AI-Virtual-Tutors.jpg";
import vrClassrooms from "../industries/Education/VR-Classrooms.jpg";
import metaverseLearning from "../industries/Education/Metaverse-Learning.jpg";
import virtualYearbook from "../industries/Education/Virtual-Yearbook.jpg";


const vimeoVideoUrls = {
    interactiveVirtualTour: "https://player.vimeo.com/video/936134178?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0&byline=0&amp;portrait=0",    
    virtualLabs: "https://player.vimeo.com/video/936162933?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0&byline=0&amp;portrait=0",
    Intractive: "https://www.youtube.com/embed/p3DLBVzCgDQ?controls=0&modestbranding=1&showinfo=0&rel=0",
};
const dataItem = [
  {
    id: 1,
    img: immersiveRealityImg,
    iframe: '',
    title: "Immersive Reality Curriculum Development",
    desc: `<p>Craft educational curriculam enriched with immersive reality, blending augmented and virtual experiences to enhance student engagement and foster a dynamic learning environment.<p>`,
    button: false,
    topHeadingDesign: true,
    slug: "",
    btnTitle: "",
    video: "",
    link: "",
  },
  
  {
    id: 2,
    img: '',
    iframe: '',
    title: "Interactive Virtual Campus Tour",
    desc: `<p>Enhance education with interactive virtual tours, providing students immersive journeys through historical landmarks, scientific environments, or cultural sites, enriching their understanding and exploration.<p>`,
    button: true,
    slug: "A08",
    btnTitle: "Learn more",
    video: vimeoVideoUrls['interactiveVirtualTour'],
    link: "/services/interactive-virtual-tours",
  },
  
  {
    id: 3,
    img: workshopsImg,
    title: "Workshop & Training",
    desc: `<p>Offer a virtual exploration of your campus, showcasing classrooms, libraries, and special facilities with immersive 360-degree tours that help attract and engage prospective students.<p>`,
    button: true,
    topHeadingDesign: true,
    slug: "",
    btnTitle: "Book a Virtual Tour Demo",
    video: '',
    link: "https://calendly.com/raed-avr/",
    target:"blank"
  },
  {
    id: 4,
    img: virtualYearbookImg,
    title: "Virtual Yearbook",
    desc: `<p>Revolutionize traditions with a virtual yearbook, offering an interactive and multimedia-rich platform for students to celebrate memories, achievements, and personal growth in a digital and dynamic format.<p>`,
    button: false,
    topHeadingDesign: true,
    headingRight: true,
    slug: "",
    btnTitle: "",
    video: '',
    link: "",
  },
  {
    id: 5,
    img: '',
    title: "Virtual Labs",
    desc: `<p>Transform traditional labs into virtual spaces, allowing students to conduct experiments, explore scientific concepts, and collaborate in a simulated environment, enhancing practical learning in a digital realm.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: vimeoVideoUrls['virtualLabs'],
    link: "",
  },
  {
    id: 6,
    img: AiTutors,
    title: "AI Virtual Tutors/Avatars",
    desc: `<p>Provide students with 24/7 academic support through AI-powered virtual tutors that adapt to individual learning styles, boosting comprehension by up to 40%.<p>`,
    button: true,
    topHeadingDesign: true,
    slug: "",
    btnTitle: "Customize Your Virtual Tutors",
    video: "",
    link: "/contact",
  },
  {
    id: 7,
    img: vrClassrooms,
    title: "VR Classrooms",
    desc: `<p>Transform lessons into interactive experiences with VR Classrooms, where students can engage deeply with subjects, from exploring historical sites to conducting virtual labs, leading to 75% higher retention.<p>`,
    button: true,
    slug: "",
    btnTitle: "Contact Us to Learn More About VR Learning",
    topHeadingDesign: true,
    headingRight: true,
    video: "",
    link: "/contact",
  },
  {
    id: 8,
    img: '',
    iframe: 'https://portal.arviewz.com/products/175/modelviewer?arModel=true',
    title: "AR Learning Modules ",
    desc: `<p>Bring complex topics to life with AR overlays, making it easier for students to grasp difficult concepts. These modules increase engagement by 65% with interactive content.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: "",
    link: "",
  },
  {
    id: 9,
    img: metaverseLearning,
    iframe: '',
    title: "Metaverse Learning",
    subHeading: "The Future of Education",
    desc: `<p>Step into a virtual classroom with Metaverse Learning. Offering immersive, interactive experiences, students can explore, collaborate, and learn in ways that traditional education can’t match.<p>`,
    button: true,
    slug: "",
    btnTitle: "Connect with Us  for a Demo",
    video: "",
    link: "/contact",
  },
  {
    id: 10,
    img: virtualYearbook,
    iframe: '',
    title: "Virtual Yearbook",
    subHeading: "Digital Memories Redefined",
    desc: `<p>Transform your yearbook into an interactive, multimedia experience. Our Virtual Yearbook lets students, alumni, and faculty relive memories through videos, 3D models, and more—accessible anytime, anywhere.<p>`,
    button: true,
    slug: "",
    btnTitle: "Create Your Virtual Yearbook Today",
    video: "",
    link: "/contact",
  },
  // {
  //   id: 6,
  //   img: '',
  //   title: "AR Kids Entertainment",
  //   desc: `<p>Turn education into an unforgettable adventure with AR-driven, interactive experiences that bring children’s favorite 2D characters to life in 3D. Perfect for engaging young minds in fun and educational activities.<p>`,
  //   button: false,
  //   slug: "",
  //   btnTitle: "",
  //   video: "",
  //   link: "",
  //   iframe: "https://portal.arviewz.com/products/173/modelviewer?logoMobile=20&logoDesktop=20&copyRight=block"
  // },
  // {
  //   id: 6,
  //   img: '',
  //   title: "Interactive Touch Tables",
  //   desc: `<p>Promote teamwork and interactive learning with multi-touch tables, which increase retention rates by 30% through hands-on group activities and games.<p>`,
  //   button: false,
  //   slug: "",
  //   btnTitle: "",
  //   video: vimeoVideoUrls['Intractive'],
  //   link: "",
  // },
];

export default dataItem;
