import React from 'react';
import ArtDetails from '../../components/art/ArtDetails';
import ArtBanner from '../../components/banner/ArtBanner';
import Footer from '../../components/footer/Footer';
import bannerImg from "../../assets/Industries Hero/Retail-Stores-Hero.jpg"
import RetailStoresData from "../../assets/data/retails-stores";

function RetailStores(props) {
    const desc = `AVR Labs transforms retail stores with immersive technology. Elevate customer experiences through AR-enhanced displays, virtual try-on solutions, and interactive in-store navigation, creating a seamless blend of physical and digital retail environments.`;
    return (
        <div className='home-2 wrapper'>
                <ArtBanner image={bannerImg} heading="Retail & Showrooms" subheading="Industries" desc={desc}/>
                <ArtDetails data={RetailStoresData}/>
                <Footer />
        </div>
    );
}

export default RetailStores;